.auth-container {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.auth-container .auth-card {
  width: 35%;
  max-width: 500px;
  min-width: 350px;
  padding: 3%;
  border-radius: 8px;
  background-color: white;
}

@media screen and (max-width: 500px) {
  .auth-container .auth-card {
    width: 96%;
    min-width: 200px;
    border: none;
  }
}
