.header-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  background: white;
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.header-logout-button {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.header-logout-button:hover {
  background: none;
  cursor: pointer;
  text-decoration: underline;
}

.header-dropdown-menu {
  font-size: 18px;
  z-index: 9999;
}

.header-menu-button {
  font-weight: 400;
  display: flex;
  align-items: center;
}

.header-menu-button:hover {
  background: none;
  cursor: pointer;
  text-decoration: underline;
}

.header-title {
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.03rem;
  margin: 0;
  padding: 0;
}

.public-header-shadow {
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
}

.navtabs-nav-link {
  font-size: 18px;
  font-weight: 500;
}

.navtabs-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.navtabs-toggler:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

@media screen and (max-width: 768px) {
  .navtabs-nav {
    padding: 0 2%;
  }

  .drawer-item {
    text-align: left !important;
    padding: 0 1.5rem;
  }

  .offcanvas {
    --bs-offcanvas-width: none
  }
}

.absolute-right {
  position: absolute;
  right: 1rem;
}

