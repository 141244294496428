body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  min-width: 280px;
  background: #f4f4f4;
}

.custom-card {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    margin: 0 0 16px;
    padding: 16px;
    background: #fff;
}

@media (max-width: 768px) {
  .custom-card {
    border: none !important;
    padding: 0 !important;
  }
}

.center-absolute-horizontal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.modal-dialog {
    height: 90vh
}

.modal-content {
    max-height: 100%
}
