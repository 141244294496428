.container-card {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .container-card {
    border: none;
    background: none;
    padding: 0 !important;
  }
}
