#subsections-dropdown {
  display: none !important;
}

#subsections-content {
  max-height: var(--content-height);
  overflow-y: auto;
}

#subsections-container .dropdown-toggle::after {
  margin-left: 0.5em;
}

@media screen and (max-width: 768px) {
  #subsections-container {
    flex-direction: column !important;
  }

  #subsections-dropdown {
    display: block !important;
    width: fit-content;
  }

  #subsections-tabs {
    position: absolute;
    visibility: hidden;
  }

  #subsections-content {
    max-height: fit-content;
    overflow-y: hidden;
  }
}
