.pdfIcon {
    width: 2rem;
    height: 2rem;
    margin-right: 5px;
}

.pdfIconSmall {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}
